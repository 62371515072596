import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import PanelItem from './PanelItem';

import { section, title, accButton, accItem } from './styles.module.scss';

const AccordionSec = ({ data }) => {
  return (
    <section className={section}>
      <div className="scr-wrap">
        <h2
          className={title}
          dangerouslySetInnerHTML={{ __html: data.accordion.title }}
        ></h2>

        <Accordion allowZeroExpanded={true} preExpanded={[0]}>
          {data.accordion.list.map((item, idx) => {
            return (
              <AccordionItem
                key={item.title + idx}
                className={accItem}
                uuid={idx}
              >
                <AccordionItemHeading>
                  <AccordionItemButton className={accButton}>
                    {item.title}
                  </AccordionItemButton>
                </AccordionItemHeading>

                <AccordionItemPanel>
                  <PanelItem data={item.content} />
                </AccordionItemPanel>
              </AccordionItem>
            );
          })}
        </Accordion>
      </div>
    </section>
  );
};

export default AccordionSec;
