import React from 'react';
import classnames from 'classnames';
import useIntersection from 'hooks/useIntersection';

import {
  section,
  inner,
  title,
  subTitle,
  date,
  button,
  text,
  train,
  anim,
} from './styles.module.scss';

const Hero = ({ data }) => {
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.05,
    triggerOnce: false,
  });

  return (
    <section className={section} ref={scrollWrapper}>
      <div ref={inViewRefSec} className={inner}>
        <div className="scr-wrap">
          <h1 className={title}>{data.hero.title}</h1>

          <div className={subTitle}>
            <p className={date}>
              {data.date}
              <span></span>
              {data.time}
            </p>

            <a
              href={data.hero.button.url}
              className={button}
              id="screver-btq-hero-join"
            >
              {data.hero.button.title}
            </a>
          </div>

          <div
            className={text}
            dangerouslySetInnerHTML={{ __html: data.hero.text }}
          ></div>
        </div>

        <ul className={classnames(train, inViewSec ? anim : '')}>
          {data.hero.train.map((item, idx) => {
            return <li key={item + idx}>{item}</li>;
          })}
        </ul>
      </div>
    </section>
  );
};

export default Hero;
