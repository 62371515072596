import React from 'react';

import { section, title, list } from './styles.module.scss';

const Team = ({ data }) => {
  return (
    <section className={section}>
      <div className="scr-wrap">
        <p className={title}>{data.team.title}</p>
      </div>

      <ul className={list}>
        {data.team.list.map((item) => {
          return (
            <li key={item}>
              <img src={`/images/screver-btq/team/${item}`} alt="" />
            </li>
          );
        })}
      </ul>
    </section>
  );
};

export default Team;
