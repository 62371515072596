import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import useAllMedia from 'hooks/graphql/useAllMedia';

const WpImage = ({ src, alt = '', ...props }) => {
  const allImages = useAllMedia();

  const image = allImages.find(({ node }) => node.sourceUrl === src);

  return image == null ? (
    <img
      src={src}
      alt={alt}
      {...props}
      loading="lazy"
      width="100%"
      height="100%"
    />
  ) : (
    <GatsbyImage
      image={getImage(image.node.localFile.childImageSharp)}
      alt={alt}
      {...props}
      placeholder="blurred"
      layout="fixed"
      // loading="lazy"
    />
  );
};

export default WpImage;
