import React from 'react';

import {
  section,
  title,
  list,
  itemList,
  time,
  itemWrap,
} from './styles.module.scss';

import ProgramItem from './ProgramItem';

const Program = ({ data }) => {
  return (
    <section className={section}>
      <div className="scr-wrap">
        <h2 className={title}>{data.program.title}</h2>

        <ul className={list}>
          {data.program.list.map((item, idx) => {
            return (
              <li key={item.time + idx}>
                <div className={itemList}>
                  <p className={time}>{item.time}</p>

                  <div className={itemWrap}>
                    {item.event.map((subItem, i) => {
                      return <ProgramItem data={subItem} key={subItem.title} />;
                    })}
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
};

export default Program;
