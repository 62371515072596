import React from 'react';

import {
  section,
  title,
  list,
  listItem,
  icon,
  img,
  text,
} from './styles.module.scss';

const Expect = ({ data }) => {
  return (
    <section className={section}>
      <div className="scr-wrap">
        <h2 className={title}>{data.expect.title}</h2>

        <ul className={list}>
          {data.expect.list.map((item) => {
            return (
              <li key={item.text}>
                <div className={listItem}>
                  {item.icon ? (
                    <img
                      src={`/images/screver-btq/${item.icon}`}
                      alt="Emoji"
                      className={icon}
                    />
                  ) : (
                    <img
                      src={`/images/screver-btq/${item.img}`}
                      alt="Carly Simone"
                      className={img}
                    />
                  )}

                  <p
                    className={text}
                    dangerouslySetInnerHTML={{ __html: item.text }}
                  ></p>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
};

export default Expect;
