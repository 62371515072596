import React from 'react';

import WpImage from 'components/WPImage/index';

import {
  container,
  banner,
  content,
  header,
  boby,
  title,
  label,
  logo,
} from './panelItem.module.scss';

const PanelItem = ({ data }) => {
  return (
    <div className={container}>
      {/* <WpImage src={data.banner} className={banner} /> */}

      <img src={data.banner} className={banner} alt="" />

      <div className={content}>
        <div className={header}>
          <h2 className={title} style={{ color: data.title.color }}>
            {data.title.content}

            {/* <WpImage src={data.label} className={label} /> */}

            <img src={data.label} className={label} alt="" />
          </h2>

          {/* <WpImage src={data.logo} className={logo} /> */}

          <img src={data.logo} className={logo} alt="" />
        </div>

        <div
          className={boby}
          dangerouslySetInnerHTML={{ __html: data.text }}
        ></div>
      </div>
    </div>
  );
};

export default PanelItem;
