import React from 'react';
import classnames from 'classnames';

import useIntersection from 'hooks/useIntersection';

import {
  section,
  inner,
  text,
  box,
  emoji,
  imgFirst,
  imgLast,
  imgCenter,
  imgFirstLabel1,
  imgFirstLabel2,
  imgLastLabel1,
  wrap,
  label,
  green,
  greenBlue,
  blue,
  transition,
} from './styles.module.scss';

import * as styles from './styles.module.scss';

const Hair = ({ data }) => {
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.3,
    triggerOnce: false,
  });

  return (
    <section
      className={classnames(section, {
        [styles.animate]: inViewSec,
      })}
      ref={scrollWrapper}
    >
      <div className="scr-wrap">
        <div className={wrap} ref={inViewRefSec}>
          <div className={imgFirst}>
            <img
              src="/images/screver-btq/imgFirst.gif"
              alt=""
              className={transition}
            />
            <p className={classnames(imgFirstLabel1, label, green, transition)}>
              buzz with excitement
            </p>
            <p
              className={classnames(
                imgFirstLabel2,
                label,
                greenBlue,
                transition
              )}
            >
              be fabulous
            </p>
          </div>

          <p className={classnames(imgLastLabel1, label, blue, transition)}>
            hair extravaganza
          </p>

          <img
            src="/images/screver-btq/imgCenter.gif"
            alt=""
            className={classnames(imgCenter)}
          />

          <div className={inner}>
            <div className={imgLast}>
              <img src="/images/screver-btq/imgLast.gif" alt="" />
            </div>

            <div className={box}>
              <img
                src="https://wp.screver.com/wp-content/uploads/2023/05/T1.png"
                alt=""
                className={emoji}
              />
              <p
                className={text}
                dangerouslySetInnerHTML={{ __html: data.hair.text }}
              ></p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hair;
