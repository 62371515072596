// extracted by mini-css-extract-plugin
export var section = "styles-module--section--2EuAz";
export var title = "styles-module--title--1STpP";
export var list = "styles-module--list--3jX-P";
export var itemList = "styles-module--itemList--1Rj8D";
export var time = "styles-module--time--2s8Tt";
export var eventName = "styles-module--eventName--3K-oV";
export var room = "styles-module--room--3a16Q";
export var itemWrap = "styles-module--itemWrap--2wBR4";
export var itemInner = "styles-module--itemInner--3CC4M";
export var image = "styles-module--image--R3GCO";
export var show = "styles-module--show--1eJRO";
export var description = "styles-module--description--3IJZY";