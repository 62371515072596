import React from 'react';

import Header from 'sections/ScreverBtq/Header';
import Hero from 'sections/ScreverBtq/Hero';
import Answers from 'sections/ScreverBtq/Answers';
import Expect from 'sections/ScreverBtq/Expect';
import Hair from 'sections/ScreverBtq/Hair';
import Accordion from 'sections/ScreverBtq/AccordionSec';
import Program from 'sections/ScreverBtq/Program';
import SeeYou from 'sections/ScreverBtq/SeeYou';
import Team from 'sections/ScreverBtq/Team';
import Footer from 'sections/ScreverBtq/Footer';
import Sticky from 'sections/ScreverBtq/components/Sticky';
import SEO from 'sections/Layout/SEO';
import OcxHelmet from 'sections/Layout/SEO/OcxHelmet';

import useIntersection from 'hooks/useIntersection';
import { SCREVER_BTQ } from 'mocks/screver-btq.js';

import { pageWrap } from 'styles/pages/screver-btq.module.scss';

const ScreverBtq = () => {
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.1,
    triggerOnce: false,
  });

  return (
    <>
      <OcxHelmet />

      <SEO seo={SCREVER_BTQ.seo} themeColor={'#191820'} />

      <div
        className={pageWrap}
        ref={scrollWrapper}
        style={{
          backgroundColor: inViewSec ? '#EAECF3' : '#191820',
          transition: 'all 0.4s ease-in-out',
        }}
      >
        <video
          playsInline
          autoPlay
          muted
          loop
          src="https://wp.screver.com/wp-content/uploads/2023/05/Vawe_Capptoo_Noise.mp4"
        ></video>
        <Header data={SCREVER_BTQ} />
        <main>
          <div
            style={{
              opacity: inViewSec ? 0 : 1,
              transition: 'all 0.4s ease-in-out',
            }}
          >
            <Hero data={SCREVER_BTQ} />
            <Answers data={SCREVER_BTQ} />
          </div>

          <div
            className=""
            ref={inViewRefSec}
            style={{
              opacity: inViewSec ? 1 : 0,
              transition: 'all 0.4s ease-in-out',
            }}
          >
            <Expect data={SCREVER_BTQ} />
            <Hair data={SCREVER_BTQ} />
            <Accordion data={SCREVER_BTQ} />
          </div>

          <div
            style={{
              opacity: inViewSec ? 0 : 1,
              transition: 'all 0.4s ease-in-out',
            }}
          >
            <Program data={SCREVER_BTQ} />
            <SeeYou data={SCREVER_BTQ} />
            <Team data={SCREVER_BTQ} />
          </div>
        </main>
        <Footer />

        <Sticky data={SCREVER_BTQ} />
      </div>
    </>
  );
};

export default ScreverBtq;
