import React from 'react';

import Button from './Button';

import useWindowSize from 'hooks/useWindowResize';
import { section, inner, header, title, list } from './styles.module.scss';

const Answers = ({ data }) => {
  const { width } = useWindowSize();

  return (
    <section className={section}>
      <div className="scr-wrap">
        <div className={inner}>
          <div className={header}>
            <h2 className={title}>{data.answers.title}</h2>

            {width > 992 ? (
              <Button
                data={data.answers.button}
                id="screver-btq-answers-btn-desktop"
                title="Register to brainstorm with us"
              />
            ) : (
              <></>
            )}
          </div>

          <ul className={list}>
            {data.answers.list.map((item) => {
              return <li key={item}>{item}</li>;
            })}
          </ul>

          {width <= 992 ? (
            <Button
              data={data.answers.button}
              id="screver-btq-answers-btn-mobile"
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </section>
  );
};

export default Answers;
