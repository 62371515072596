import React from 'react';

import { button } from './styles.module.scss';

const JoinBtn = ({ data, id, title }) => {
  return (
    <a href={data.url} className={button} id={id}>
      <span>{title ? title : data.title}</span>
    </a>
  );
};

export default JoinBtn;
