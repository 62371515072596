import React from 'react';

import { button } from './button.module.scss';

const Button = ({ data }) => {
  return (
    <a href={data.url} className={button}>
      {data.title}
    </a>
  );
};

export default Button;
