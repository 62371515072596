import React from 'react';

import { box, text, link, divider } from './styles.module.scss';

const Sticky = ({ data }) => {
  return (
    <div className={box}>
      <div className={text}>
        <p>
          {data.place},&#160;<u>aja</u>&#160;CLUB
        </p>

        <span className={divider}></span>

        <p>
          {data.date}
          <span className={divider}></span>
          {data.time}
        </p>
      </div>

      <a
        href={data.sticky.button.url}
        className={link}
        id="screver-btq-sticky-iamin"
      >
        {data.sticky.button.title}
      </a>
    </div>
  );
};

export default Sticky;
