import React from 'react';

import WpImage from 'components/WPImage/index';
import JoinBtn from '../components/JoinBtn';

import {
  section,
  title,
  box,
  images,
  imageItem,
  footer,
  place,
  location,
  footerInner,
} from './styles.module.scss';

const SeeYou = ({ data }) => {
  return (
    <section className={section}>
      <div className="scr-wrap">
        <h2 className={title}>{data.seeyou.title}</h2>

        <div className={box}>
          <div className={images}>
            {data.seeyou.list.map((item) => (
              <div className={imageItem} key={item}>
                <WpImage src={item} />
              </div>
            ))}
          </div>

          <div className={footer}>
            <div className={footerInner}>
              <h2 className={place}>{data.seeyou.place}</h2>
              <a
                href="https://goo.gl/maps/GEJJAXPML48FdVxi8"
                target="_blank"
                rel="noreferrer"
                className={location}
                id="screver-btq-seeyou-join"
              >
                {data.seeyou.location}
              </a>
            </div>

            <JoinBtn data={data.components.joinBtn} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default SeeYou;
