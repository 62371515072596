import React from 'react';

import { header, inner, button, about } from './styles.module.scss';

const Header = ({ data }) => {
  return (
    <header className={header}>
      <div className="scr-wrap">
        <div className={inner}>
          <p className={about}>
            {data.place}
            <span></span>
            {data.date} <u>&#160; (pick any day)</u>
            <span></span>
            {data.time}
          </p>

          <a
            href={data.header.button.url}
            className={button}
            id="screver-btq-header"
          >
            <span>{data.header.button.title}</span>
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
