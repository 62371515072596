// extracted by mini-css-extract-plugin
export var section = "styles-module--section--1alOW";
export var wrap = "styles-module--wrap--1jBVQ";
export var inner = "styles-module--inner--2m13S";
export var emoji = "styles-module--emoji--2Z9-Y";
export var box = "styles-module--box--2sNk3";
export var text = "styles-module--text--20xIQ";
export var imgFirst = "styles-module--imgFirst--1odjW";
export var transition = "styles-module--transition--3pbam";
export var imgCenter = "styles-module--imgCenter--2Swjp";
export var imgLast = "styles-module--imgLast--28wbI";
export var imgFirstLabel1 = "styles-module--imgFirstLabel1--2GPiY";
export var imgFirstLabel2 = "styles-module--imgFirstLabel2--9Ygln";
export var imgLastLabel1 = "styles-module--imgLastLabel1--27vlr";
export var label = "styles-module--label--187L4";
export var green = "styles-module--green--2yg1G";
export var greenBlue = "styles-module--greenBlue--3ymP-";
export var blue = "styles-module--blue--X04YD";
export var animate = "styles-module--animate--V7Xzd";