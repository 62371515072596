import React, { useState, useCallback } from 'react';
import classnames from 'classnames';

import WpImage from 'components/WPImage/index';

import {
  eventName,
  room,
  show,
  itemInner,
  image,
  description,
} from './styles.module.scss';
import './image.scss';

const ProgramItem = ({ data }) => {
  const [isShow, setIsShow] = useState(false);

  const setMouseEnter = useCallback(() => {
    setIsShow(true);
  }, []);

  const setMouseLeave = useCallback(() => {
    setIsShow(false);
  }, []);

  return (
    <div className={classnames(itemInner, 'scr-program-room')}>
      <WpImage
        src={data.image}
        className={classnames(
          image,
          `index-${data.index}`,
          isShow ? show : false
        )}
      />

      <div className="">
        <p className={eventName}>{data.title}</p>

        {data.description.map((item) => {
          return (
            <div
              key={item}
              className={description}
              dangerouslySetInnerHTML={{ __html: item }}
            ></div>
          );
        })}
      </div>
      {data.room ? (
        <button
          type="button"
          className={room}
          onMouseEnter={setMouseEnter}
          onMouseLeave={setMouseLeave}
        >
          {data.room}
        </button>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ProgramItem;
